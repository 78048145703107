// @ts-check
import { API } from "aws-amplify";
import { OrgInfo, ProductAccess, Tech, UserInfo } from "./ApiTypes";

export type NewAccountInfo = {
  email: string;
  orgDomain?: string;
  newDomain?: string;
  newTemplatesAccess: boolean;
  newPrereleasesAccess: boolean;
  newFreeTrialAccess: boolean;
  newLicenseEmail?: string;
  newAccess: ProductAccess[];
  newName: string;
};

export const accessIncludingNewTechs = (
  access: ProductAccess[]
): ProductAccess[] => {
  const result = access;

  const newTechs: Tech[] = ["gcp", "azsev", "k8s-sev", "apm", "northstar"];

  newTechs.forEach((tech) => {
    if (!result.some((cur) => cur.type === tech)) {
      result.push({
        type: tech,
        endsAt: "expired",
        license: undefined,
        licenseType: "trial",
        licenseYaml: undefined,
      } as ProductAccess);
    }
  });

  return result;
};

export const retrieveOrgsListAPI = async ({
  email,
}: {
  email: string;
}): Promise<
  | {
      type: "success";
      orgs: OrgInfo[];
    }
  | { type: "error"; reason: string }
> => {
  const apiName = "useraccessapi";
  const path = `/api/v1/useraccess`;
  const myInit = {
    headers: {},
    queryStringParameters: {
      email,
    },
  };

  try {
    const result: any[] = await API.get(apiName, path, myInit);

    return {
      type: "success",
      orgs: result.map((cur) => ({
        ...cur,
        access: accessIncludingNewTechs(JSON.parse(cur.access)),
      })),
    };
  } catch (error) {
    console.error(
      "Retrieve user access API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      reason: `We were unable to receive access levels for orgs. This likely means you do not have necessary permissions`,
    };
  }
};

export const retrieveNoOrgUsersListAPI = async ({
  email,
}: {
  email: string;
}): Promise<
  | {
      type: "success";
      users: UserInfo[];
    }
  | { type: "error"; reason: string }
> => {
  const apiName = "useraccessapi";
  const path = `/api/v1/useraccess/no-org`;
  const myInit = {
    headers: {},
    queryStringParameters: {
      email,
    },
  };

  try {
    const result: { users: UserInfo[] } = await API.get(apiName, path, myInit);

    console.log(
      "Retrieve users with no org API call succeeded: " + JSON.stringify(result)
    );

    return {
      type: "success",
      users: result.users,
    };
  } catch (error) {
    console.error(
      "Retrieve users with no org API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      reason: `We were unable to receive access levels for user management. This likely means you do not have necessary permissions`,
    };
  }
};

export const batchInviteUsersToOrgAPI = async (
  email: string,
  invitedUserEmails: string[],
  orgId: string
): Promise<{
  results: { success: boolean; email: string; errorMessage?: string }[];
}> => {
  const apiName = "useraccessapi";
  const path = `/api/v1/invite/batch`;
  const myInit = {
    headers: {},
    queryStringParameters: {
      email,
    },
    body: {
      userEmails: invitedUserEmails,
      orgId,
    },
  };

  try {
    const result: {
      results: { success: boolean; email: string; errorMessage?: string }[];
    } = await API.post(apiName, path, myInit);

    console.log(
      "Batch invite users to org API call results: " + JSON.stringify(result)
    );

    return result;
  } catch (error) {
    console.error(
      "Batch invite users to org API call failed",
      JSON.stringify(error)
    );

    throw new Error(
      `We were unable to receive access levels for user management. This likely means you do not have necessary permissions`
    );
  }
};

/**
 * @returns {Promise<{type: "success", accountInfo: import("../../amplify/backend/function/awsonboardwebapputillayer/opt").AccountInfo } | {type: "error", reason: string}>}
 */
export const updateUserAccessAPI = async (
  {
    email,
    orgDomain,
    newAccess,
    newName,
    newDomain,
    newLicenseEmail,
    newTemplatesAccess,
    newPrereleasesAccess,
    newFreeTrialAccess,
  }: NewAccountInfo,
  orgId?: string
) => {
  if (!orgId && !orgDomain) {
    return {
      type: "error",
      reason: `The org has no orgId or domain.`,
    };
  }

  const apiName = "useraccessapi";
  const path = `/api/v1/useraccess`;
  const myInit = {
    headers: {},
    body: {
      newAccess,
      newName,
      newDomain,
      newLicenseEmail,
      newTemplatesAccess,
      newPrereleasesAccess,
      newFreeTrialAccess,
    },
    queryStringParameters: {
      email,
      orgDomain,
      orgId,
    },
  };

  try {
    const result = await API.put(apiName, path, myInit);

    console.log(
      "Update user access API call succeeded: " + JSON.stringify(result)
    );

    return {
      type: "success",
      accountInfo: result,
    };
  } catch (error) {
    const { data } = (error as any).response;

    console.error("Create org API call failed", data);

    return {
      type: "error",
      reason:
        data ??
        `We were unable to update access levels for another organization.`,
    };
  }
};

/**
 *
 * @returns {Promise<{type: "success", accountInfo: import("../../amplify/backend/function/awsonboardwebapputillayer/opt").AccountInfo } | {type: "error", reason: string}>}
 */
export const createOrgAPI = async ({
  email,
  orgDomain,
  newAccess,
  newName,
  newLicenseEmail,
  newTemplatesAccess,
  newPrereleasesAccess,
  newFreeTrialAccess,
}: NewAccountInfo) => {
  const apiName = "useraccessapi";
  const path = `/api/v1/useraccess`;
  const myInit = {
    headers: {},
    body: {
      newAccess,
      newName,
      newLicenseEmail,
      newTemplatesAccess,
      newPrereleasesAccess,
      newFreeTrialAccess,
    },
    queryStringParameters: {
      email,
      orgDomain,
    },
  };

  try {
    const result = await API.post(apiName, path, myInit);

    console.log("Create org API call succeeded: " + JSON.stringify(result));

    return {
      type: "success",
      accountInfo: result,
    };
  } catch (error) {
    const { data } = (error as any).response;

    console.error("Create org API call failed", data);

    return {
      type: "error",
      reason: data,
    };
  }
};
